<template>
  <div class="page-login-desktop">
    <Table
        label="کد های ورود"
        icon="mdi mdi-format-list-bulleted"
        :table="table">
      <tr
          v-for="(item,index) in table.items"
          :key="index"
      >
        <td class="text-center">{{ index + 1 }}</td>
        <td class="text-center">{{ item.phoneNumber }}</td>
        <td class="text-center">{{ item.code }}</td>
        <td class="text-center">
          <v-chip color="success" v-if="item.status">
            وارد شده
          </v-chip>
          <v-chip v-else color="error">
            وارد نشده
          </v-chip>
        </td>
        <td class="text-center">{{ item.created_at }}</td>
      </tr>
    </Table>

    <Pagination
        v-model="filters.skip"
        :key="filters.skip"
        :totalItems="table.totalCount"
        :perPage="filters.take"
        @change="(page)=>{fetchItems({skip: page})}"
    />
  </div>
</template>

<script>

import Table from "@/components/Table"
import Pagination from "@/components/Pagination"
import {verificationCode} from "@Newfiling/module-auth/src/api";

export default {
  name: 'DesktopLogin',

  components: {
    Table,
    Pagination
  },

  data() {
    return {
      filters: {
        skip: 0,
        take: 10,
      },
      table: {
        headers: [
          '#',
          'موبایل',
          'کد',
          'وضعیت',
          'تاریخ',

        ],
        items: [],
        totalCount: 0,
        loading: false,
      },
    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'کد های ورود',
        disabled: true,
        href: '/panel/auth/verify-code',
      },
    ])
  },

  mounted() {
    this.fetchItems();
  },

  methods: {
    async fetchItems(filters = {}) {
      this.filters = {
        ...this.filters,
        ...filters
      }
      this.table.loading = true;
      try {
        const res = (await verificationCode({
          ...this.filters,
          skip: this.filters.skip * this.filters.take
        }))?.data || {
          list: [],
          recordcount: 0,
        };

        console.log({res})

        this.table.items = res.list.map(item => {
          return {
            phoneNumber: item.mobile,
            code: item.code,
            status: item.loginStatus,
            created_at: item.posttime,
          }
        });
        this.table.totalCount = res.recordcount;
      } catch (e) {
        console.log(e)
      }
      this.table.loading = false;
    }
  }

}
</script>
